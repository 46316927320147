import { AdjustmentType } from "@/components/batch/adjust/models/batch-adjust.model";
import AuditTableComponent from "@/components/inventory/Audit/auditTable/AuditTable.component";
import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import {
  Audit,
  AuditResponse,
  AuditStatus,
  deafultFilter,
  defaultBatchAudit,
  Filter,
  ItemAudit,
  PayloadAudit
} from "@/interfaces/audit";
import { configCurrentBatchForm } from "@/metadata/audit";
import { i18n } from "@/plugins/i18n";
import { metrcEnabled } from "@/router.utils";
import { auditService } from "@/services/audit.service";
import { batchService } from "@/services/batch.service";
import { productService } from "@/services/product.service";
import { PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  DynamicFormComponent,
  FormField
} from "helix-vue-components";
import pick from "lodash/pick";
import union from "lodash/union";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./AuditDetails.template.vue";

@Component({
  mixins: [Template],
  components: {
    AuditTableComponent,
    "helix-form": DynamicFormComponent
  },
  inject: ["$changes"]
})
export default class AuditDetailsComponent extends Vue {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public configCurrentBatchForm: FormField[] = [];
  public configFilterForm: FormField[] = [];
  public filters: Filter | null = { ...deafultFilter };
  public operationId: string = "";
  public currentAudit: Audit | null = null;
  public currentBatchAudit: ItemAudit | null = { ...defaultBatchAudit };
  public nextBatchAudit: ItemAudit | null = { ...defaultBatchAudit };
  public statusAudit!: AuditStatus;
  public metrcAdjustmentReasons: AdjustmentType[] = [];
  public bioTrackAdjustmentReasons: AdjustmentType[] = [];
  public hasMetrc = false;
  public formKey = "";
  public hasErrors = true;
  public configSubAction!: {
    icon: string;
    action: () => Promise<void>;
  };
  public scannedBatchTraceId: string = "";
  public loading = false;
  public key = "";
  public redirectFlag!: boolean;
  public hasModifyPermission = true;

  public readonly() {
    return (
      ![AuditStatus.OPENED, AuditStatus.TRC_CONNECTION_ERROR].includes(
        this.statusAudit
      ) &&
      !(
        AuditStatus.ERROR &&
        this.currentAudit &&
        (!!this.currentAudit.summary.fractions_counted ||
          !!this.currentAudit.summary.fractions_remaining)
      )
    );
  }

  public roomsFormat(rooms: Array<{ name: string }>) {
    return rooms.map(r => r.name).join(", ");
  }

  public mounted() {
    // @ts-ignore
    this.$barcodeScanner.init(this.onBarcodeScanned);
    this.operationId = this.$route.params.operationId;
    this.setPageNav({
      title: "audit.inventory_audit",
      isLoading: () => this.loading
    });
    this.loadAudit();
    EventBus.$on("reloadDetailAudit", this.emitLoadAudit);
    if (this.$route.name === "audit-view") {
      this.hasModifyPermission = false;
    }
  }

  /**
   * Takes action on bar code scanning.
   */
  public onBarcodeScanned(code: string) {
    this.scannedBatchTraceId = code;
    EventBus.$emit("scanningCode", code);
  }

  public back() {
    this.$router.back();
  }

  public beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }

  public setUpdatedErrorsOfMetrc() {
    if (this.hasMetrc && this.currentBatchAudit!.errors!.length) {
      this.hasErrors = false;
    }
  }
  public async onClickBatchAuditUpdate() {
    const form = await (this.$refs[
      "form-audit"
    ] as DynamicFormComponent).submit();
    if (form.valid) {
      const data = form.currentModel as ItemAudit;
      if (data && data.batch_fraction_uid) {
        this.loading = true;
        let payloadFields = ["batch_fraction_uid", "new_quantity", "reason"];
        if (this.hasMetrc) {
          const field =
            this.hasErrorInCurrentBatch() ||
            (form.currentModel!.new_quantity !==
              form.currentModel!.old_quantity &&
              form.currentModel!.has_cannabis &&
              form.currentModel!.options &&
              form.currentModel!.options.tracking_id)
              ? ["options", "batch_adjustment_type_id"]
              : ["batch_adjustment_type_id"];
          payloadFields = union(payloadFields, field);
        }
        if (this.bioTrackTraceEnabled) {
          payloadFields = [...payloadFields, "batch_adjustment_type_id"];
        }
        const resp = await auditService.updateItemAudited(
          this.currentAudit!.real_time_audit, // this dont is implement
          this.currentAudit!.reference,
          { audit_item: pick(data, payloadFields) as PayloadAudit }
        );
        if (resp) {
          this.setUpdatedErrorsOfMetrc();
          this.currentBatchAudit = this.nextBatchAudit || {
            ...defaultBatchAudit
          };
          this.setAudit();
          EventBus.$emit("refreshTables");
        } else {
          this.loading = false;
        }
      }
    }
  }

  public async onBatchSelect(batches: {
    batchSelected: ItemAudit | null;
    nextBatch: ItemAudit | null;
  }) {
    this.loading = true;
    let data: ItemAudit | null = null;
    if (batches.batchSelected && !batches.batchSelected.batch_fraction_uid) {
      data = await auditService.getItemsCurrentAudit(
        this.operationId,
        batches.batchSelected.batchUid!,
        [batches.batchSelected.inventory_location_id!]
      );
      data!.batchUid = data!.batch_uid;
      if (this.bioTrackTraceEnabled && data!.biotrack_traceability_id) {
        data!.batch_uid = data!.biotrack_traceability_id;
      } else {
        data!.batch_uid = data!.batch_uid;
      }
      this.nextBatchAudit = batches.nextBatch;
      this.currentBatchAudit = (data && {
        ...data,
        product_info: data!.product_info
      }) || { ...defaultBatchAudit };
    } else {
      this.currentBatchAudit = (batches.batchSelected && {
        ...batches.batchSelected
      }) || { ...defaultBatchAudit };
      this.nextBatchAudit = batches.nextBatch;
    }
    if (
      this.currentBatchAudit.errors &&
      this.currentBatchAudit.errors!.includes("[]")
    ) {
      this.currentBatchAudit.errors = [];
    }

    if (
      this.hasMetrc &&
      this.currentBatchAudit.has_cannabis &&
      !this.currentBatchAudit.tracking_id
    ) {
      this.currentBatchAudit.options = {
        tracking_id:
          (this.currentBatchAudit.options &&
            this.currentBatchAudit.options.tracking_id) ||
          this.currentBatchAudit.tracking_id
      };
    }
    if (this.hasErrorInCurrentBatch()) {
      this.currentBatchAudit.options = {
        tracking_id:
          (this.currentBatchAudit.options &&
            this.currentBatchAudit.options.tracking_id) ||
          this.currentBatchAudit.tracking_id,
        metrc_weighable:
          this.currentBatchAudit.product_info &&
          !!this.currentBatchAudit.product_info!.metrc_weighable,
        metrc_unfinish_tag:
          this.currentBatchAudit.errors &&
          !this.currentBatchAudit.errors!.find(error =>
            error.includes("Finished")
          )
      };
    }
    this.$nextTick(() => {
      this.currentBatchAudit = {
        ...this.currentBatchAudit!,
        reason:
          this.currentBatchAudit!.reason ||
          this.$t("audit.note_inventory_audit").toString()
      };
    });
    if (!this.currentBatchAudit!.batch_adjustment_type_id) {
      setTimeout(
        () =>
          (this.currentBatchAudit = {
            ...this.currentBatchAudit!,
            batch_adjustment_type_id: this.bioTrackAdjustmentReasons[2].id
          }),
        0
      );
    }

    this.loading = false;
  }

  /**
   * onChange
   */
  public onChange(data: { model: ItemAudit; modelField: string }) {
    if (data.modelField === "new_quantity") {
      this.currentBatchAudit!.options!.metrc_unfinish_tag = !!data.model
        .new_quantity;
      this.currentBatchAudit!.new_quantity = data.model.new_quantity;
    } else if (data.modelField === "batch_adjustment_type_id") {
      this.currentBatchAudit!.batch_adjustment_type_id =
        data.model.batch_adjustment_type_id;
    }
  }

  public openMetrcLoadinWindow() {
    if (this.hasMetrc /*&& this.hasMarijuana()*/) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(() => {
          // nothing to do
        });
    }
  }

  public async continueWithUnacountedItems() {
    if (this.currentAudit!.summary.fractions_remaining) {
      const resp = await this.modalActionServices({
        title: "audit.unaccounted_items",
        body: "audit.batches_in_pending_msg"
      });
      return resp;
    }
    return true;
  }

  public async continueWithErrors() {
    if (this.hasErrors) {
      const resp = await this.modalActionServices({
        title: "audit.close_audit",
        body: "audit.question_errors"
      });
      return resp;
    }
    return true;
  }

  public async closeAudit() {
    if (
      (await this.continueWithErrors()) &&
      (await this.continueWithUnacountedItems())
    ) {
      this.modalActionServices(this.setActionClose());
    }
  }

  public actionAudit() {
    const actionService: {
      [key: string]: {
        action: (operationUid: string) => Promise<void>;
        postAction: () => void;
        title: string;
        body: string;
      };
    } = {
      OPENED: {
        action: auditService.saveAudit,
        postAction: () => null,
        title: "audit.save_audit",
        body: "audit.save_audit_msg"
      },
      PAUSED: {
        action: auditService.resumeAudit,
        postAction: () => null,
        title: "audit.resume_audit",
        body: "audit.resume_audit_msg"
      }
    };
    this.modalActionServices(
      actionService[this.statusAudit] || actionService.OPENED
    );
  }

  public emitLoadAudit() {
    this.loadAudit();
    this.loading = false;
  }
  public async setAudit() {
    this.currentAudit = await auditService.getAudit(this.operationId);
  }

  public getRoomName(roomId: number): string {
    if (
      this.currentAudit &&
      this.currentAudit.inventory_locations &&
      this.currentAudit.inventory_locations.length &&
      roomId
    ) {
      return this.currentAudit.inventory_locations.find(i => i.id === roomId)!
        .name;
    }
    return "--";
  }

  public hasErrorInCurrentBatch() {
    return !!(
      this.currentBatchAudit &&
      this.currentBatchAudit.status === AuditStatus.ERROR
    );
  }
  public async renderPopupModal(
    titleName: string,
    messageName: string,
    descriptionName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue?: string,
    loadingName?: string
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: this.$t(titleName),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: this.$t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: this.$t(descriptionName),
              style: "fontSize:23px"
            },
            loading: loadingName,
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    return confirm;
  }
  private async showLoadingPopUp() {
    if (await this.$validator.validateAll()) {
      setTimeout(() => {
        this.renderPopupModal(
          "audit.inventory_audit_title",
          "audit.audit_in_process",
          "audit.audit_loading_description",
          false,
          false,
          "",
          `${i18n.t("audit.audit_loading_name")}`
        );
      }, 0);
    }
  }

  private async showEndAuditPopUp() {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "audit.inventory_audit_title",
        "audit.end_audit",
        "audit.end_audit_description",
        true,
        true
      );
    }
  }
  private async showErrorPopUp(errorMessage: string) {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "audit.inventory_audit_title",
        "audit.audit_failed",
        i18n.t("audit.audit_failed_description") + `${errorMessage}`,
        true,
        false,
        "OK"
      );
    }
  }
  private async bulkApiCall(
    operationUid: string,
    pinCode: string | null,
    resp: AuditResponse
  ): Promise<any> {
    const endAuditPopUpResponse = await this.showEndAuditPopUp();
    if (endAuditPopUpResponse) {
      await this.showErrorPopUp(resp.message);
      if (this.bioTrackTraceEnabled) {
        await this.showLoadingPopUp();
      }
      const closeAuditResp = await auditService.closeAudit(
        operationUid,
        pinCode,
        this.bioTrackTraceEnabled
      );
      EventBus.$emit("removePopup", true);
      if (closeAuditResp.status === "error") {
        await this.bulkApiCall(operationUid, pinCode, closeAuditResp);
      } else {
        this.redirectFlag = true;
        return this.redirectFlag;
      }
    } else {
      this.loadAudit();
    }
  }

  private setActionClose() {
    return {
      action: async (operationUid: string) => {
        const pinCode = await auditService.authorize();
        if (!pinCode) {
          return;
        }
        const resp = await auditService.closeAudit(
          operationUid,
          pinCode,
          this.bioTrackTraceEnabled
        );
        if (resp.status === "errorWrongPin") {
          return;
        }
        this.openMetrcLoadinWindow();
        if (resp && this.hasMetrc && resp.errors) {
          EventBus.$emit("mtrcLoadingEvent", {
            show: true,
            errorList: resp.errors
          });
          return null;
        }

        if (resp.status === "error" && this.bioTrackTraceEnabled) {
          await this.bulkApiCall(operationUid, pinCode, resp);
          if (!this.redirectFlag) {
            return;
          }
        }
        return resp;
      },
      postAction: () => (!this.hasMetrc ? this.back() : null),
      title: "audit.close_audit",
      body: "audit.close_audit_msg"
    };
  }

  private async loadAudit() {
    this.loading = true;
    await this.setAudit();
    this.hasErrors = this.currentAudit!.has_errors;
    this.hasMetrc = metrcEnabled() && this.currentAudit!.has_cannabis_items;
    if (this.hasMetrc) {
      this.metrcAdjustmentReasons = await batchService.getAdjustmentTypes();
      if (!this.metrcAdjustmentReasons.length) {
        this.statusAudit = AuditStatus.NOT_AVAILABLE;
      } else {
        this.statusAudit = this.currentAudit!.summary.status as AuditStatus;
      }
    } else {
      this.statusAudit = this.currentAudit!.summary.status as AuditStatus;
    }
    if (this.bioTrackTraceEnabled) {
      this.bioTrackAdjustmentReasons = await batchService.getAdjustmentTypes();
    }

    this.configCurrentBatchForm = configCurrentBatchForm(
      this.onClickBatchAuditUpdate,
      this.readonly,
      this.hasMetrc,
      this.hasErrorInCurrentBatch,
      this.metrcAdjustmentReasons,
      this.bioTrackTraceEnabled,
      this.bioTrackAdjustmentReasons,
      this.hasModifyPermission
    );
    this.loadNav();
    this.filters = {
      rooms: [this.currentAudit!.inventory_locations![0].id],
      productCategories: []
    };
    this.loading = false;
  }

  private iconAction(): string {
    const icon: { [key: string]: string } = {
      OPENED: "fal fa-save",
      PAUSED: "far fa-play"
    };
    return icon[this.statusAudit] || "fal fa-save";
  }

  private async modalActionServices(
    data: {
      action?: (operationUid: string) => Promise<any>;
      postAction?: () => void;
      title: string;
      body: string;
    },
    icon: string = "fal fa-exclamation-triangle"
  ) {
    try {
      await this.$modals.load(
        ConfirmModalComponent,
        {
          size: "normal",
          positionY: "center"
        },
        {
          modalData: {
            title: data.title,
            body: this.$t(data.body),
            icon
          }
        }
      );
      if (data.action) {
        this.loading = true;
        const resp = await data.action(this.currentAudit!.reference);
        if (resp && data.postAction) {
          data.postAction();
        }
        this.loading = false;
      }
      return true;
    } catch {
      return false;
    }
  }

  private loadNav() {
    this.setPageNav({
      title: `${this.$t("audit.inventory_audit")} ${
        this.currentAudit!.real_time_audit
          ? " - " + this.$t("audit.real_time").toString()
          : ""
      }`,
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            visibleCondition: () =>
              this.hasModifyPermission &&
              (([
                AuditStatus.OPENED,
                AuditStatus.PAUSED,
                AuditStatus.TRC_CONNECTION_ERROR
              ] as string[]).includes(this.statusAudit) ||
                (AuditStatus.ERROR &&
                  (!!this.currentAudit!.summary.fractions_counted ||
                    !!this.currentAudit!.summary.fractions_remaining))),
            action: this.closeAudit,
            vuetifyProps: () => ({
              disabled: !(
                [AuditStatus.OPENED, AuditStatus.TRC_CONNECTION_ERROR].includes(
                  this.statusAudit
                ) ||
                (AuditStatus.ERROR &&
                  (!!this.currentAudit!.summary.fractions_counted ||
                    !!this.currentAudit!.summary.fractions_remaining))
              ),
              loading: this.loading,
              fab: true,
              small: true
            }),
            tooltip: {
              text: "audit.close_audit",
              displayCondition: () => true
            }
          },
          {
            icon: "fal fa-times",
            action: this.back,
            vuetifyProps: () => ({
              fab: true,
              small: true
            }),
            tooltip: {
              text: "audit.back",
              displayCondition: () => true
            }
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              visibleCondition: () =>
                ([
                  AuditStatus.OPENED,
                  AuditStatus.PAUSED,
                  AuditStatus.TRC_CONNECTION_ERROR
                ] as string[]).includes(this.statusAudit) ||
                (AuditStatus.ERROR &&
                  (!!this.currentAudit!.summary.fractions_counted ||
                    !!this.currentAudit!.summary.fractions_remaining)),
              icon: this.iconAction(),
              action: this.actionAudit,
              vuetifyProps: () => ({
                loading: this.loading,
                fab: true,
                small: true
              }),
              tooltip: {
                text:
                  this.statusAudit === AuditStatus.OPENED
                    ? "audit.save_audit"
                    : "audit.resume_audit",
                displayCondition: () => false
              }
            }
          ]
        }
      }
    });
  }
}
