import { EventBus } from "@/event-bus";
import { Filter, ItemAudit } from "@/interfaces/audit";
import { RetailSettings } from "@/interfaces/retailSettings";
import {
  batchAudit,
  mapperBatchesAudit,
  mapperProductAudit,
  mapperProductBatchAudit,
  productAudit,
  productBatchAudit
} from "@/metadata/audit";
import { auditService } from "@/services/audit.service";
import { productService } from "@/services/product.service";
import {
  DataTablePlaceholderComponent,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./AuditTable.template.vue";

interface ConfigTableAudit {
  headers: TableHeader[];
  items: ItemAudit[];
  rowActions: TableAction[];
  pagination: TablePagination | null;
}

const DEFAULT_CONFIG = {
  items: [],
  rowActions: [
    {
      icon: "fal fa-ballot-check",
      action: () => null,
      tooltip: "audit.audit"
    }
  ],
  pagination: { ...TablePaginationDefault }
};
@Component({
  mixins: [Template],
  components: {
    DataTablePlaceholderComponent,
    TableComponent
  }
})
export default class AuditTableComponent extends Vue {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ required: true }) public filtersAudit!: Filter;
  @Prop({ required: true }) public operationId!: string;
  @Prop({ required: true }) public currentBatch!: ItemAudit;
  @Prop({ required: true }) public readonly!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public currentProduct: ItemAudit | null = null;
  public loading = false;
  public tabActive = "group-products";
  public iterativeProcedure = false;
  public refreshProductList = false;
  public scannedBatchUid!: string | null;
  public columnToSortBy: string = "";
  public sortDescending: boolean = true;
  public configProducts: ConfigTableAudit = {
    ...cloneDeep(DEFAULT_CONFIG),
    headers: productAudit
  };
  public configProductBatch: ConfigTableAudit = {
    ...cloneDeep(DEFAULT_CONFIG),
    headers: productBatchAudit
  };
  public configBatch: ConfigTableAudit = {
    ...cloneDeep(DEFAULT_CONFIG),
    headers: batchAudit
  };

  public currentRowActions(action: (item: ItemAudit) => void): TableAction[] {
    const icon = this.readonly ? "fad fa-eye" : "fal fa-ballot-check";
    const tooltip = this.readonly
      ? this.$t("audit.show_audit").toString()
      : this.$t("audit.audit").toString();
    return [{ icon, action, tooltip }];
  }

  public onRefreshAudit() {
    if (this.currentProduct && this.tabActive === "group-products") {
      this.actionProduct(this.currentProduct);
      this.refreshProductList = true;
    } else {
      this.loadBatchesTab();
    }
    this.iterativeProcedure = true;
  }

  @Watch("filtersAudit", { deep: true })
  public resetItemSelected() {
    this.currentProduct = null;
    const action =
      this.tabActive === "group-products"
        ? this.loadProducts
        : this.loadBatchesTab;
    if (this.filtersAudit) {
      action();
    }
  }

  public onSort(header: TableHeader) {
    this.columnToSortBy = header.value;
    if (header.value === "product_info.name") {
      this.columnToSortBy = "name";
    }
    if (header.value === "product_info.category.name") {
      this.columnToSortBy = "category.name";
    }
    this.sortDescending = !!header.descending;
    if (this.tabActive === "group-products") {
      if (header.value === "available_reserved") {
        this.columnToSortBy = "total_old_quantity";
      }
      this.loadProducts();
    } else {
      if (header.value === "available_reserved") {
        this.columnToSortBy = "old_quantity";
      }
      this.loadBatchesTab();
    }
  }

  public async actionProduct(product: ItemAudit) {
    this.loading = true;
    const resp = await auditService.getBatchesInProductAudits(
      this.operationId,
      product.sku!,
      this.filtersAudit,
      this.configProductBatch.pagination,
      this.scannedBatchUid!
    );
    if (resp) {
      this.configProductBatch = {
        ...this.configProductBatch,
        items: mapperBatchesAudit(resp),
        pagination: await auditService.getPagination(true)
      };
      this.configProductBatch.rowActions = this.currentRowActions(
        this.actionBathes
      );
      this.currentProduct = product;
    }

    this.loading = false;
    if (this.bioTrackTraceEnabled) {
      this.configProductBatch.items.forEach(item => {
        if (item.biotrack_traceability_id) {
          item.batch_uid = item.biotrack_traceability_id;
        } else {
          item.batch_uid = item.batch_uid;
        }
      });
    }
    if (this.scannedBatchUid && this.scannedBatchUid !== null) {
      const scannedBatchIndex = this.configProductBatch.items.findIndex(
        batch => batch.batch_uid === this.scannedBatchUid
      );
      this.actionBathes(this.configProductBatch.items[scannedBatchIndex]);
    } else {
      this.actionBathes(
        (this.iterativeProcedure && this.currentBatch) ||
          this.configProductBatch.items[0]
      );
    }
  }

  public setModel(batchSelected: ItemAudit | null = null) {
    const currentBatchList =
      this.tabActive === "group-products"
        ? this.configProductBatch.items
        : this.configBatch.items;
    const index =
      (batchSelected &&
        currentBatchList.findIndex(
          b => b.batch_uid === batchSelected!.batch_uid
        )) ||
      0;
    const nextBatch =
      index < currentBatchList.length - 1 ? currentBatchList[index + 1] : null;
    if (this.tabActive === "group-batches") {
      batchSelected!.new_quantity = String(batchSelected!.new_quantity!).slice(
        0,
        -1
      );
    }

    this.$emit("onChange", { batchSelected, nextBatch });
  }

  public changePagination(pagination: TablePagination) {
    this.iterativeProcedure = false;
    if (this.tabActive === "group-products") {
      if (this.currentProduct) {
        this.configProductBatch.pagination = pagination;
        this.actionProduct(this.currentProduct);
      } else {
        this.configProducts.pagination = pagination;
        this.loadProducts();
      }
    } else {
      this.configBatch.pagination = pagination;
      this.loadBatchesTab();
    }
  }
  public mounted() {
    EventBus.$on("refreshTables", this.onRefreshAudit);
    EventBus.$on("scanningCode", (code: string) => {
      this.onScanningCode(code);
    });
  }

  public async onScanningCode(code: string) {
    this.scannedBatchUid = String(code);
    if (this.tabActive === "group-batches") {
      this.loading = true;

      await auditService
        .getBatchesCurrentAudit({
          operationUid: this.operationId,
          filter: this.filtersAudit,
          pagination: this.configBatch.pagination,
          batchUid: String(code)
        })
        .then(async resp => {
          if (resp) {
            resp!.data.forEach(item => {
              if (this.bioTrackTraceEnabled && item.biotrack_traceability_id) {
                item.batch_uid = item.biotrack_traceability_id;
              } else {
                item.batch_uid = item.batch_uid;
              }
            });
            this.configBatch = {
              ...this.configBatch,
              items: mapperProductBatchAudit(resp.data),
              pagination: await auditService.getPagination(true)
            };
            this.configBatch.rowActions = this.currentRowActions(
              this.actionBathes
            );
          }

          this.loading = false;
          if (this.configBatch.items.length) {
            this.actionBathes(this.configBatch.items[0]);
          }
        });
    } else {
      this.loading = true;
      this.configBatch.pagination = null;
      this.currentProduct = null;
      this.refreshProductList = false;

      await auditService
        .getProductAudits({
          operationUid: this.operationId,
          filter: this.filtersAudit,
          pagination: this.configProducts.pagination,
          batchUid: String(code)
        })
        .then(async resp => {
          if (resp) {
            resp!.data.forEach(item => {
              if (this.bioTrackTraceEnabled && item.biotrack_traceability_id) {
                item.batch_uid = item.biotrack_traceability_id;
              } else {
                item.batch_uid = item.batch_uid;
              }
            });
            this.configProducts = {
              ...this.configProducts,
              items: mapperProductAudit(resp),
              pagination: await auditService.getPagination(true)
            };
            this.configProducts.rowActions = this.currentRowActions(
              this.actionProduct
            );
          }

          this.loading = false;
          if (this.configProducts.items.length) {
            this.actionProduct(this.configProducts.items[0]);
          }
        });
    }
    this.columnToSortBy = "";
    this.sortDescending = false;
  }

  public async loadBatchesTab() {
    this.tabActive = "group-batches";
    this.loading = true;
    const resp = await auditService.getBatchesCurrentAudit({
      operationUid: this.operationId,
      filter: this.filtersAudit,
      pagination: this.configBatch.pagination,
      columnToSortBy: this.columnToSortBy,
      sortDescending: this.sortDescending
    });
    this.loading = false;
    resp!.data.forEach(item => {
      if (this.bioTrackTraceEnabled && item.biotrack_traceability_id) {
        item.batch_uid = item.biotrack_traceability_id;
      } else {
        item.batch_uid = item.batch_uid;
      }
    });
    if (resp) {
      this.configBatch = {
        ...this.configBatch,
        items: mapperProductBatchAudit(resp.data),
        pagination: await auditService.getPagination(true)
      };
      this.configBatch.rowActions = this.currentRowActions(this.actionBathes);
    }
    if (this.configBatch.items.length) {
      this.actionBathes(
        (this.iterativeProcedure && this.currentBatch) ||
          this.configBatch.items[0]
      );
    } else {
      this.setModel();
    }
    this.columnToSortBy = "";
    this.sortDescending = false;
  }

  private loadProducts() {
    this.tabActive = "group-products";
    this.configBatch.pagination = null;
    this.currentProduct = null;
    this.loading = true;
    this.refreshProductList = false;
    auditService
      .getProductAudits({
        operationUid: this.operationId,
        filter: this.filtersAudit,
        pagination: this.configProducts.pagination,
        columnToSortBy: this.columnToSortBy,
        sortDescending: this.sortDescending
      })
      .then(async resp => {
        if (resp) {
          this.configProducts = {
            ...this.configProducts,
            items: mapperProductAudit(resp),
            pagination: await auditService.getPagination(true)
          };
          this.configProducts.rowActions = this.currentRowActions(
            this.actionProduct
          );
        }
        this.loading = false;
      });
    this.columnToSortBy = "";
    this.sortDescending = false;
    this.setModel();
  }

  private async actionBathes(batch: ItemAudit) {
    let model;
    if (batch.batch_uid) {
      let currentBatchList;
      if (
        this.scannedBatchUid &&
        this.scannedBatchUid !== null &&
        this.tabActive === "group-products" &&
        batch
      ) {
        currentBatchList = batch;
      } else {
        currentBatchList =
          this.tabActive === "group-products"
            ? this.configProductBatch.items[0]
            : this.configBatch.items[0];
      }

      model =
        this.iterativeProcedure ||
        currentBatchList.batch_uid === batch.batch_uid ||
        batch.batch_fraction_uid !== this.currentBatch.batch_fraction_uid
          ? {
              ...batch,
              product_info: batch.product_info!
            }
          : null;
    } else {
      this.iterativeProcedure = false;
    }
    this.scannedBatchUid = null;
    this.setModel(model);
  }
}
